<template>
  <section>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !trunks?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="trunks"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.button.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 15%"
          field="name"
          :header="$t('xpbx.settings.carrier_trunks.fields.name')"
        >
          <template #body="{ data }">
            <div>
              {{ data.name }}
            </div>
            <TableActions
              :showIsActive="false"
              :data="data"
              :id="data.id"
              @edit="editHandle"
              @delete="deleteSelectedRecord"
              :link="`/xpbx/settings/carrier/${$route?.params?.id}/trunk/${data.id}`"
            />
          </template>
        </Column>

        <Column
          sortable
          style="width: 15%"
          field="host"
          :header="$t('xpbx.settings.carrier_trunks.fields.host')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="service"
          :header="$t('xpbx.settings.carrier_trunks.fields.service')"
        >
          <template #body="{ data }">
            {{ $t(`xpbx.settings.carrier_trunks.texts.${data.service}`) }}
          </template>
        </Column>

        <Column
          sortable
          style="width: 10%"
          field="trunktype"
          :header="$t('xpbx.settings.carrier_trunks.fields.trunktype')"
        ></Column>

        <Column
          sortable
          style="width: 5%"
          field="channels"
          :header="$t('xpbx.settings.carrier_trunks.fields.channels')"
        >
        </Column>

        <Column
          sortable
          style="width: 5%"
          field="port"
          :header="$t('xpbx.settings.carrier_trunks.fields.port')"
        ></Column>

        <Column
          sortable
          style="width: 5%"
          field="priority"
          :header="$t('xpbx.settings.carrier_trunks.fields.priority')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="codecs"
          :header="$t('xpbx.settings.carrier_trunks.fields.codecs')"
        ></Column>
      </DataTable>

      <p class="text-center my-2" v-if="!trunks?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.name">
          {{
            $t("xpbx.settings.carrier_trunks.notification.confirm_delete", {
              delete: `trunk ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.carrier_trunks.dialogs.create_carrier_trunk')
          : $t('xpbx.settings.carrier_trunks.dialogs.edit_carrier_trunk')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <FormInput
        id="name"
        :error="errors?.name?.[0]"
        v-model:modelValue="record.name"
        :label="$t('xpbx.settings.carrier_trunks.labels.name')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.name')"
      />

      <!--Name -->
      <FormInput
        id="host"
        :error="errors?.host?.[0]"
        v-model:modelValue="record.host"
        :label="$t('xpbx.settings.carrier_trunks.labels.host')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.host')"
      />

      <!-- Trunk service -->
      <FormDropdown
        optionLabel="name"
        :options="servicesOptions"
        :error="errors?.service?.[0]"
        v-model:modelValue="record.service"
        :label="$t('xpbx.settings.carrier_trunks.labels.service')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.service')"
      />

      <!-- Trunk types -->
      <FormDropdown
        optionLabel="name"
        :options="trunktypeOptions"
        :error="errors?.trunktype?.[0]"
        v-model:modelValue="record.trunktype"
        :label="$t('xpbx.settings.carrier_trunks.labels.trunktype')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.trunktype')"
      />

      <NumberInput
        id="channels"
        :max="300"
        :error="errors?.channels?.[0]"
        v-model:modelValue="record.channels"
        :label="$t('xpbx.settings.carrier_trunks.labels.channels')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.channels')"
      />

      <!-- Trunk priority -->
      <FormDropdown
        :options="priorityOptions"
        :error="errors?.priority?.[0]"
        v-model:modelValue="record.priority"
        :label="$t('xpbx.settings.carrier_trunks.labels.priority')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.priority')"
      />

      <!-- Port -->
      <NumberInput
        id="number"
        :max="1000000"
        :error="errors?.port?.[0]"
        v-model:modelValue="record.port"
        :label="$t('xpbx.settings.carrier_trunks.labels.port')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.port')"
      />

      <!-- Codecs -->
      <MultiSelect
        optionLabel="name"
        :options="codecOptions"
        :error="errors?.codecs?.[0]"
        v-model:modelValue="record.codecs"
        :label="$t('xpbx.settings.carrier_trunks.labels.codecs')"
        :hint="$t('xpbx.settings.carrier_trunks.hints.codecs')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import validator from "@/composables/auth/validator";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import NumberInput from "@/modules/xpbx/components/forms/NumberInput.vue";
import MultiSelect from "@/modules/xpbx/components/forms/MultiselectForm.vue";
import { validateCreateTrunk } from "@/composables/auth/carrierValidations";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "CarrierTrunks",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputSwitch,
    FormInput,
    FormDropdown,
    NumberInput,
    MultiSelect,
    DialogButtons,
    TableActions,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const selectText = t("xpbx.settings.dropdown.select_option");

    // findCarrierTrunks
    const {
      findCarrierTrunks,
      trunks,
      createCarrierTrunk,
      updateCarrierTrunk,
      deleteCarrierTrunk,
    } = useCarriers();
    const { priorityOptions, codecOptions, getOptionValue } = useQueueOptions();

    const setDefaultRecord = () => {
      record.value = {
        name: "",
        host: "",
        service: {
          name: selectText,
          value: null,
        },
        trunktype: {
          name: selectText,
          value: null,
        },
        codecs: "",
        channels: null,
        priority: 1,
        port: null,
      };
    };

    // Data table functions
    const openNew = () => {
      setDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      setDefaultRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteCarrierTrunk(id, selectedRecord.value.id);
      }
      deleteDialog.value = false;
    };

    const getCodecs = (codecs) => {
      const splitedCodecs = codecs.split(",");
      const codecsModel = splitedCodecs.map((codec) => {
        const existedCodex = codecOptions.find(
          (option) => option.value === codec
        );
        return {
          name: existedCodex?.name || "",
          value: existedCodex?.value || "",
        };
      });

      return codecsModel?.length ? codecsModel : [];
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        const form = {
          ...event.data,
          host: event.data?.host || event.data.ip_address,
        };

        const selectedService = servicesOptions.find(
          (service) => service.value === event.data.service
        );

        const selectedTrunktype = trunktypeOptions.find(
          (trunktype) => trunktype.value === event.data.trunktype
        );

        const selectedCodecs = getCodecs(event.data.codecs);

        if (selectedService) form.service = selectedService;
        if (selectedTrunktype) form.trunktype = selectedTrunktype;
        if (selectedCodecs?.length) form.codecs = selectedCodecs;

        record.value = form;
        isEdit.value = true;
        createDialog.value = true;
      }
    };

    const editHandle = (data) => {
      isEdit.value = false;
      selectedRecord.value = data;

      const form = {
        ...data,
        host: data?.host || data.ip_address,
      };

      const selectedService = servicesOptions.find(
        (service) => service.value === data.service
      );

      const selectedTrunktype = trunktypeOptions.find(
        (trunktype) => trunktype.value === data.trunktype
      );

      const selectedCodecs = getCodecs(data.codecs);

      if (selectedService) form.service = selectedService;
      if (selectedTrunktype) form.trunktype = selectedTrunktype;
      if (selectedCodecs?.length) form.codecs = selectedCodecs;

      record.value = form;
      isEdit.value = true;
      createDialog.value = true;
    };

    const updateRecord = async (data) => {
      const id = route.params.id;

      const formData = {
        name: data.name,
        host: data.host,
        port: data.port,
        channels: data.channels,
        codecs: data?.codecs?.map((codec) => codec.value).join(","),
      };

      const service = getOptionValue(data.service);
      const trunktype = getOptionValue(data.trunktype);
      const priority = getOptionValue(data.priority);

      if (service) formData.service = service;
      if (trunktype) formData.trunktype = trunktype;
      if (priority) formData.priority = priority;

      const isValid = validateCreateTrunk(formData);

      if (!isValid) return;

      if (data?.id) {
        await updateCarrierTrunk(formData, id, data.id);
      }
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreateTrunk(data);

      console.log("isValid", isValid);

      if (isValid) {
        const codecsString = data?.codecs
          ?.map((codec) => codec.value)
          .join(",");

        const formData = {
          ...data,
          codecs: codecsString,
          priority: getOptionValue(data.priority),
          service: getOptionValue(data.service),
          trunktype: getOptionValue(data.trunktype),
        };

        await createCarrierTrunk(formData, route.params.id);
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;

      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };
    
    // "voip|moip"
    const servicesOptions = [
      { name: t(`xpbx.settings.carrier_trunks.options.voip`), value: "voip" },
      { name: t(`xpbx.settings.carrier_trunks.options.moip`), value: "moip" },
    ];

    const trunktypeOptions = [
      {
        name: t(`xpbx.settings.carrier_trunks.options.incoming`),
        value: "INC",
      },
      {
        name: t(`xpbx.settings.carrier_trunks.options.outgoing`),
        value: "OUT",
      },
      { name: t(`xpbx.settings.carrier_trunks.options.blended`), value: "BLE" },
    ];

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findCarrierTrunks(route.params.id);
    });

    return {
      isEdit,
      trunks,
      submitted,
      record,
      errors,
      clearErrors,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      trunktypeOptions,
      selectedRecords,
      codecOptions,
      servicesOptions,
      priorityOptions,
      // Methods
      create,
      openNew,
      editHandle,
      closeDialog,
      getCodecs,
      setDefaultRecord,
      updateCarrierTrunk,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>