<template>
  <div class="flex mb-4 mt-4 items-center gap-x-6">
    <div class="flex gap-x-0.5 items-center flex-wrap w-full">
      <Letter
        v-for="letter in letters"
        :key="letter"
        :letter="letter"
        :active="isActive(letter)"
        @click.prevent="setActiveLetter(letter)"
      />
      <div
        class="hover:opacity-90 cursor-pointer ml-auto"
        :title="$t('text.app.common.close')"
        @click.prevent="resetActiveLetter"
        v-show="activeLetter != ''"
      >
        <Icon name="times" color="#000" />
      </div>
    </div>
  </div>
</template>

<script>
// import Icon from "@/components/Icon";
import { ref, watch } from "vue";
import Letter from "@/modules/widget/components/pricesAndTariffs/LetterButton";

export default {
  name: "AlphaBetFilter",

  components: {
    Letter,
  },

  setup(props, { emit }) {
    const search = ref("");
    const activeLetter = ref("");

    const letters = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];

    const setActiveLetter = (letter) => {
      emit("activeLetter", letter);
      activeLetter.value = letter;
    };

    const resetActiveLetter = () => {
      emit("activeLetter", null);
      activeLetter.value = "";
    };

    const searchHandler = () => {
      activeLetter.value = "";
      emit("activeLetter", search.value);
    };

    const isActive = (letter) => {
      return letter.toLowerCase() == activeLetter.value.toLowerCase();
    };

    return {
      search,
      letters,
      activeLetter,
      //   Functions
      isActive,
      searchHandler,
      setActiveLetter,
      resetActiveLetter,
    };
  },
};
</script>

