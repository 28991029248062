<template>
  <div class="flex items-center gap-2 mb-2">
    <div class="flex-auto">
      <label for="billing-1" class="text-sm block mb-2">
        {{ $t("xpbx.settings.carrier_rates.billing_form.rate_per_1") }}
      </label>
      <InputNumber
        v-model="value1"
        inputId="billing-1"
        mode="decimal"
        showButtons
        :min="0"
        :max="10"
        fluid
      />
    </div>
    <div class="flex-auto">
      <label for="billing-2" class="text-sm block mb-2">
        {{ $t("xpbx.settings.carrier_rates.billing_form.rate_per_2") }}
      </label>
      <InputNumber
        v-model="value2"
        inputId="billing-2"
        mode="decimal"
        showButtons
        :min="0"
        :max="150"
        :step="30"
        fluid
      />
    </div>
    <div class="flex-auto">
      <div class="flex items-center justify-between">
        <label for="billing-3" class="text-sm block mb-2">
          {{ $t("xpbx.settings.carrier_rates.billing_form.rate_per_3") }}
        </label>
        <i
          class="fa-solid fa-circle-info cursor-pointer"
          @click="showDialog = true"
        ></i>
      </div>
      <InputNumber
        v-model="value3"
        inputId="billing-3"
        mode="decimal"
        showButtons
        :min="0"
        :max="20"
        :step="1"
        fluid
      />
    </div>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '550px' }"
    header="Information"
    :modal="true"
    class="p-fluid relative"
  >
    <div class="confirmation-content">
      <h3>{{ $t("xpbx.settings.carrier_rates.texts.example") }} 1:</h3>
      <div class="text-xs">
        <p>{{ $t("xpbx.settings.carrier_rates.texts.setup_time") }} 0</p>
        <p>{{ $t("xpbx.settings.carrier_rates.texts.min_billing_time") }} 60</p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.rest_billing_time") }} 60
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_1") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_2") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_3") }}
        </p>
      </div>

      <h3 class="mt-2">
        {{ $t("xpbx.settings.carrier_rates.texts.example") }} 2:
      </h3>
      <div class="text-xs">
        <p>{{ $t("xpbx.settings.carrier_rates.texts.setup_time") }} 0</p>
        <p>{{ $t("xpbx.settings.carrier_rates.texts.min_billing_time") }} 60</p>
        <p>{{ $t("xpbx.settings.carrier_rates.texts.rest_billing_time") }} 1</p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_4") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_5") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_6") }}
        </p>
      </div>

      <h3 class="mt-2">
        {{ $t("xpbx.settings.carrier_rates.texts.example") }} 3:
      </h3>
      <div class="text-xs">
        <p>{{ $t("xpbx.settings.carrier_rates.texts.setup_time") }} 0</p>
        <p>{{ $t("xpbx.settings.carrier_rates.texts.min_billing_time") }} 1</p>
        <p>{{ $t("xpbx.settings.carrier_rates.texts.rest_billing_time") }} 1</p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_7") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_8") }}
        </p>
        <p>
          {{ $t("xpbx.settings.carrier_rates.texts.billing_form_9") }}
        </p>
      </div>
    </div>
    <template #footer>
      <Button text @click="showDialog = false" :label="$t('xpbx.button.ok')" />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch, computed } from "vue";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "BillingForm",

  components: {
    InputNumber,
    Dialog,
    Button,
    QueueHint,
  },

  props: {
    billing: {
      type: String,
      required: false,
    },

    hint: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const value1 = ref(getBilling(1));
    const value2 = ref(getBilling(2));
    const value3 = ref(getBilling(3));
    const showDialog = ref(false);

    function getBilling(index) {
      return +props.billing?.split(":")[index - 1] || 0;
    }

    const billingValue = computed(
      () => `${value1.value}:${value2.value}:${value3.value}`
    );

    watch(
      () => billingValue,
      (newValue) => emit("updateBilling", newValue?.value),
      { deep: true }
    );

    return {
      value1,
      value2,
      value3,
      showDialog,
      getBilling,
      billingValue,
    };
  },
};
</script>

<style>
</style>