import { ref, inject } from "vue";

export default function useCalendar() {
  const formatDateWIthHours = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = +month > 9 ? month : `0${month}`;
    let day = date.getDate();
    day = +day > 9 ? day : `0${day}`;
    let hours = date.getHours();
    hours = +hours > 9 ? hours : `0${hours}`;
    let minutes = date.getMinutes();
    minutes = +minutes > 9 ? minutes : `0${minutes}`;
    let seconds = date.getSeconds();
    seconds = +seconds > 9 ? seconds : `0${seconds}`;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return {
    // Dates
    formatDateWIthHours,
  };
}
