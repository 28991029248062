<template>
  <section>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !filteredRates?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="filteredRates"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :globalFilterFields="['prefix']"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.button.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto">
              <InputText
                class="search-input"
                v-model="filters['global'].value"
                :placeholder="$t('xpbx.placeholders.search')"
              />
            </div>
          </div>
          <div class="flex justify-end">
            <AlphaBetFilter @activeLetter="filterRecords" />
          </div>
          <!-- Add search button -->
        </template>

        <Column
          sortable
          style="width: 10%"
          field="prefix"
          :header="$t('xpbx.settings.carrier_rates.fields.prefix')"
        >
          <template #body="{ data }">
            <div>
              {{ data.prefix }}
            </div>
            <TableActions
              :data="{ ...data }"
              :id="data.id"
              :showIsActive="false"
              @edit="editHandle"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>

        <Column
          sortable
          style="width: 10%"
          field="carrier_rate"
          :header="$t('xpbx.settings.carrier_rates.fields.carrier_rate')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="carrier_local_rate"
          :header="$t('xpbx.settings.carrier_rates.fields.carrier_local_rate')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="carrier_origin_rate"
          :header="$t('xpbx.settings.carrier_rates.fields.carrier_origin_rate')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="carrier_setup_rate"
          :header="$t('xpbx.settings.carrier_rates.fields.carrier_setup_rate')"
        ></Column>

        <Column
          sortable
          style="width: 15%"
          field="billing_model"
          :header="$t('xpbx.settings.carrier_rates.fields.billing_model')"
        >
        </Column>

        <Column
          sortable
          style="width: 5%"
          field="cli"
          :header="$t('xpbx.settings.carrier_rates.fields.cli')"
        >
          <template #body="{ data }">
            <div>
              {{
                data.cli
                  ? $t("xpbx.settings.carrier_rates.fields.yes")
                  : $t("xpbx.settings.carrier_rates.fields.no")
              }}
            </div>
          </template>
        </Column>

        <Column
          sortable
          style="width: 10%"
          field="effective_date"
          :header="$t('xpbx.settings.carrier_rates.fields.effective_date')"
        >
          <template #body="{ data }">
            <DateItem :value="data?.effective_date" />
          </template>
        </Column>
      </DataTable>

      <p class="text-center my-2" v-if="!rates?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.prefix">
          {{
            $t("xpbx.settings.carrier_rates.notification.confirm_delete", {
              delete: `rate ${selectedRecord?.prefix}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.carrier_rates.dialogs.edit_carrer_rate')
          : $t('xpbx.settings.carrier_rates.dialogs.create_carrer_rate')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Prefix name -->
      <h2>Select</h2>
      <SelectSearchKeyValue
        class="my-5"
        id="prefix_name"
        name="prefix_name"
        :options="prefixNameOptions"
        v-model="recordPrefixName"
        :error="errors.prefix_name?.[0]"
        v-if="prefixes?.length"
        :hint="$t('xpbx.settings.carrier_rates.hints.prefix_name')"
        :headerLabel="$t('xpbx.settings.carrier_rates.labels.prefix_name')"
      />

      <!-- Prefix -->
      <SelectSearchKeyValue
        name="prefix"
        id="prefix"
        :options="prefixOptions"
        :headerLabel="$t('xpbx.settings.carrier_rates.labels.prefix')"
        v-model="record.prefix"
        class="my-5"
        @update:modelValue="setPreffix($event)"
        @setValue="setPrefixValue"
        :error="errors.prefix?.[0]"
        :hint="$t('xpbx.settings.carrier_rates.hints.prefix')"
      />

      <!-- Carrier rate-->
      <NumberInput
        id="carrier_rate"
        :isDecimal="true"
        :error="errors?.carrier_rate?.[0]"
        v-model:modelValue="record.carrier_rate"
        :label="$t('xpbx.settings.carrier_rates.labels.carrier_rate')"
        :hint="$t('xpbx.settings.carrier_rates.hints.carrier_rate')"
      />

      <!-- Local rate-->
      <NumberInput
        :isDecimal="true"
        id="carrier_local_rate"
        :error="errors?.carrier_local_rate?.[0]"
        v-model:modelValue="record.carrier_local_rate"
        :label="$t('xpbx.settings.carrier_rates.labels.carrier_local_rate')"
        :hint="$t('xpbx.settings.carrier_rates.hints.carrier_local_rate')"
      />

      <!-- Origin rate-->
      <NumberInput
        :isDecimal="true"
        id="carrier_origin_rate"
        :error="errors?.carrier_origin_rate?.[0]"
        v-model:modelValue="record.carrier_origin_rate"
        :label="$t('xpbx.settings.carrier_rates.labels.carrier_origin_rate')"
        :hint="$t('xpbx.settings.carrier_rates.hints.carrier_origin_rate')"
      />

      <!-- Setup rate-->
      <NumberInput
        :isDecimal="true"
        id="carrier_setup_rate"
        :error="errors?.carrier_setup_rate?.[0]"
        v-model:modelValue="record.carrier_setup_rate"
        :label="$t('xpbx.settings.carrier_rates.labels.carrier_setup_rate')"
        :hint="$t('xpbx.settings.carrier_rates.hints.carrier_setup_rate')"
      />

      <!--Billing -->
      <BillingForm
        @updateBilling="updateBilling"
        :billing="record.billing_model"
        :hint="$t('xpbx.settings.carrier_rates.hints.billing_model')"
      />

      <!-- CLI-->
      <InputSwitch
        v-model:modelValue="record.cli"
        :label="$t('xpbx.settings.carrier_rates.labels.cli')"
        :hint="$t('xpbx.settings.carrier_rates.hints.cli')"
      />

      <!-- Date -->
      <div class="field flex flex-col justify-start">
        <label class="text-left">{{
          $t("xpbx.settings.carrier_rates.labels.effective_date")
        }}</label>
        <Calendar
          class="w-44"
          v-model="record.effective_date"
          showTime
          hourFormat="24"
        />
      </div>
      <QueueHint
        :title="$t('xpbx.settings.carrier_rates.hints.effective_date')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import useCalendar from "@/modules/xpbx/composables/useCalendar";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import NumberInput from "@/modules/xpbx/components/forms/NumberInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import { validateCarrierRate } from "@/composables/auth/carrierValidations";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import SelectSearchKeyValue from "@/shared/SelectSearchKeyValue.vue";
import BillingForm from "@/modules/xpbx/pages/settings/components/BillingForm/BillingForm.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import AlphaBetFilter from "@/modules/xpbx/pages/settings/components/AlphaBetFilter/AlphaBetFilter.vue";

export default {
  name: "CarrierRates",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    Calendar,
    DateItem,
    FormInput,
    NumberInput,
    FormDropdown,
    DialogButtons,
    TableActions,
    QueueHint,
    InputSwitch,
    BillingForm,
    AlphaBetFilter,
    SelectSearchKeyValue,
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const prefixId = ref(null);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const recordPrefixName = ref(null);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const {
      rates,
      prefixes,
      filterRates,
      findPrefixes,
      filteredRates,
      findCarrierRates,
      createCarrierRate,
      updateCarrierRate,
      deleteCarrierRate,
    } = useCarriers();
    const { formatDateWIthHours } = useCalendar();
    const { timeoutSelect, periodOptions } = useQueueOptions();

    const prefixName = computed(() => recordPrefixName.value);

    const prefixNameOptions = computed(() => {
      let uniquePrefixNames = [
        ...new Set(prefixes.value.map((item) => item.prefix_name)),
      ];

      return uniquePrefixNames.map((item) => ({
        name: item,
        value: item,
      }));
    });

    const prefixOptions = computed(() => {
      if (!prefixName?.value)
        return prefixes.value.map((item) => ({
          name: item?.prefix,
          value: item?.prefix,
        }));

      const search = prefixName?.value.toLowerCase();

      const filteredPrefixes = prefixes?.value.filter((option) => {
        return option.prefix_name.toLowerCase().includes(search);
      });

      if (!filteredPrefixes.length)
        return prefixes.value.map((item) => ({
          name: item?.prefix,
          value: item?.prefix,
        }));

      return filteredPrefixes.map((item) => ({
        name: item?.prefix,
        value: item?.prefix,
      }));
    });

    const filterRecords = (letter) => {
      if (!letter) {
        filterRates([], true);
        return;
      }
      console.log("letter", letter);

      console.log("rates", rates.value);

      const fRates = rates.value.filter((item) => {
        return item.prefix_name
          ?.toLowerCase()
          ?.startsWith(letter.toLowerCase());
      });

      filterRates(fRates);

      console.log("fRates", fRates);
    };

    // Data table functions
    const addDefaultRecord = () => {
      record.value = {
        billing_model: "",
        cli: true,
        carrier_origin_rate: 0,
        carrier_setup_rate: 0,
        carrier_local_rate: 0,
        carrier_rate: 0,
        prefix: null,
        prefix_name: null,
        effective_date: new Date(),
      };

      prefixNameOptions.value = null;
    };

    const openNew = () => {
      addDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      addDefaultRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const editHandle = (data) => {
      isEdit.value = true;
      selectedRecord.value = data;
      record.value = {
        ...data,
        cli: data.cli === 1 ? true : false,
      };
      createDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      confirmDeleteSelected();
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteCarrierRate(id, selectedRecord.value.id);
      }
      deleteDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        const form = {
          ...event.data,
        };

        record.value = form;
        isEdit.value = true;
        createDialog.value = true;
      }
    };

    const generateFormData = (data) => {
      return {
        ...data,
        cli: data.cli ? 1 : 0,
        prefix: prefixId.value,
        effective_date: formatDateWIthHours(new Date(data.effective_date)),
      };
    };

    const updateRecord = async (data) => {
      const id = route.params.id;

      const formData = generateFormData(data);

      if (formData?.id) await updateCarrierRate(formData, id, formData.id);
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCarrierRate(data);

      if (isValid) {
        const id = route.params.id;
        const formData = generateFormData(data);

        await createCarrierRate(formData, id);
        createDialog.value = false;
      }
    };

    const setPrefixValue = (value) => {
      prefixId.value = value;
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const setPreffix = (value) => {
      record.value.prefix = value;
    };

    const updateBilling = (value) => {
      record.value.billing_model = value;
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findPrefixes();
      await findCarrierRates(route.params.id);
    });

    return {
      isEdit,
      rates,
      filters,
      prefixId,
      prefixes,
      submitted,
      record,
      errors,
      filteredRates,
      clearErrors,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      timeoutSelect,
      periodOptions,
      prefixOptions,
      recordPrefixName,
      prefixNameOptions,
      selectedRecords,
      // Methods
      create,
      openNew,
      editHandle,
      setPreffix,
      filterRates,
      updateBilling,
      closeDialog,
      filterRecords,
      onRowSelect,
      onRowUnselect,
      setPrefixValue,
      addDefaultRecord,
      generateFormData,
      deleteSelectedRecord,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>