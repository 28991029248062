<template>
  <div class="field flex justify-start items-start flex-col">
    <label>{{ label }}</label>
    <div class="">
      <MultiSelect
        :filter="filter"
        v-model="selectedValue"
        :options="options"
        :optionLabel="optionLabel"
        :placeholder="placeholder"
        class="w-full md:w-14rem"
      />
    </div>
    <small class="p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <div class="mb-4" v-else></div>
</template>

<script>
import { ref, watch } from "vue";
import MultiSelect from "primevue/multiselect";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "MultiSelectForm",

  components: {
    QueueHint,
    MultiSelect,
  },

  props: {
    modelValue: {
      required: true,
      type: [String, Object, Number, null],
    },

    options: {
      required: true,
      type: [Array, Object],
    },

    error: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    optionLabel: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    filter: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const selectedValue = ref(props.modelValue ? props?.modelValue : null);

    watch(selectedValue, (value) => {
      const emitedValue = value?.value || value;

      emit("update:modelValue", emitedValue);
    });

    return {
      selectedValue,
    };
  },
};
</script>