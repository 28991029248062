<template>
  <section class="w-full queue-general-wrapper scrollable-wrapper">
    <div class="relative flex gap-4">
      <div class="w-2/4" v-if="isLoaded">
        <!--Name -->
        <FormInput
          id="name"
          v-model:modelValue="carrier.carrier_name"
          :label="$t('xpbx.settings.carrier_general.labels.name')"
          :hint="$t('xpbx.settings.carrier_general.hints.name')"
        />

        <!--Timezone -->

        <Timezones
          v-model="carrier.timezone"
          :showTitle="false"
          label="label.app.common.timezone_select"
        />

        <!--Is active -->
        <InputSwitch
          v-model:modelValue="carrier.is_active"
          :label="$t('xpbx.settings.carrier_general.labels.is_active')"
        />
        <Divider />
        <div class="flex justify-start">
          <Button class="mt-2" @click="edit" :label="$t('xpbx.button.save')" />
        </div>
      </div>

      <!-- Right container -->
      <div class="w-2/5 ml-2"></div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import Divider from "primevue/divider";
import Timezones from "@/shared/Timezones";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";

export default {
  name: "CarrierGeneral",

  components: {
    Button,
    Divider,
    InputSwitch,
    FormInput,
    Timezones,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const submitted = ref(false);
    const isLoaded = ref(false);
    const { findCarrier, updateCarrier, carrier } = useCarriers();
    const timezones = computed(() => store.getters.timezoneOptions);

    const edit = async () => {
      submitted.value = true;

      const formData = {
        ...carrier.value,
        is_active: carrier.value.is_active ? 1 : 0,
      };

      if (carrier.value.timezone)
        formData.timezone = carrier.value.timezone?.value
          ? carrier.value.timezone?.value
          : carrier.value.timezone || "";

      await updateCarrier(formData, route.params.id);
    };

    onMounted(async () => {
      await findCarrier(route.params.id);

      isLoaded.value = true;
    });

    return {
      submitted,
      timezones,
      carrier,
      isLoaded,
      //   Methods
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-button {
  padding: 4px 16px;
}

.queue-general-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}

@media (min-height: 900px) {
  .queue-general-wrapper {
    max-height: 75vh;
    overflow-y: auto;
  }
}
</style>