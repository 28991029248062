<template>
  <div class="relative">
    <label :for="name" class="text-left" v-show="!!headerLabel">
      {{ headerLabel }}
    </label>
    <div class="form-control">
      <div class="text-lg font-inter">
        <div class="form-control">
          <div class="dropdown-with-arrow">
            <input
              type="text"
              name="search"
              class="block border-0 border-b-half w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100 border-light-green placeholder-gray-500 focus:border-green-700"
              :placeholder="placeholder"
              autocomplete="off"
              @click.stop="open"
              @focus="open"
              @keyup="navigate"
              @keyup.stop.esc="close"
              @keyup.stop.enter="updateAndClose(active)"
              :value="modelValue"
              @input="filterOptions($event.target.value)"
            />
          </div>
        </div>
      </div>

      <dropdown
        :id="dropdownId"
        v-if="isActiveDropdown(dropdownId)"
        classes="top-11 left-0.5"
        style="width: 100%; max-height: 13rem; overflow-y: auto"
        v-on-click-outside="close"
      >
        <template v-if="filteredOptions.length">
          <dropdown-item
            classes="dropdown-item bg-white hover:bg-gray-200"
            :class="{ 'active-dropdown': index == 0 }"
            style="padding-top: 5px !important; padding-bottom: 5px !important"
            @click.prevent="updateAndClose(option)"
            v-for="(option, index) in filteredOptions"
            :key="option.value"
          >
            {{ option.name }}
          </dropdown-item>
        </template>
        <dropdown-item v-else>{{
          $t("label.app.common.no_match")
        }}</dropdown-item>
      </dropdown>
    </div>
    <small class="p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
</template>

<script>
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "SelectSearchKeyValue",
  inheritAttrs: false,

  components: {
    QueueHint,
  },
};
</script>

<script setup>
import { useAttrs, computed, ref, onMounted, watch } from "vue";
import useActiveToggler from "@/composables/useActiveToggler";
import { vOnClickOutside } from "@vueuse/components";
import lodash from "lodash";

const props = defineProps({
  options: {
    type: [Object, Array],
    required: true,
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: "Please Select",
  },
  userLanguages: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
  placeholder: {
    type: String,
    required: false,
    default: "",
  },
  hint: {
    type: String,
    required: false,
    default: "",
  },
  footerLabel: {
    required: false,
    type: String,
    default: "",
  },
  headerLabel: {
    required: false,
    type: String,
    default: "",
  },
  error: {
    required: false,
    default: null,
    type: String,
  },
  name: {
    required: false,
    default: "",
    type: String,
  },
});

const emit = defineEmits(["update:modelValue", "setValue"]);

const active = ref("");

const attrs = useAttrs();

const filteredOptions = ref(props.options || []);

const dropdownId = computed(() => attrs.id || "customSelectDropdown");

const modelName = () => {
  let obj = props.options.filter(function (ele) {
    if (ele.name == props.modelValue) {
      return ele;
    }
  });

  if (obj[0] == undefined) {
    return;
  } else {
    return obj[0].name;
  }
};

const filterOptions = (search) => {
  active.value = search;
  filteredOptions.value = props?.options.filter((option) => {
    return option.name.toLowerCase().includes(search.toLowerCase());
  });
};

const updateAndClose = (option) => {
  const value = option.name;

  if (value == "" || value == null || value == undefined) return;
  emit("update:modelValue", value);
  emit("setValue", option.value);
  toggleDropdown(dropdownId.value);
};

const close = () => {
  if (isActiveDropdown(dropdownId.value)) toggleDropdown(dropdownId.value);
};

const open = () => {
  if (isActiveDropdown(dropdownId.value) == false)
    toggleDropdown(dropdownId.value);
};

watch(
  () => props.options,
  (newValue) => {
    filteredOptions.value = newValue;
  },
  { deep: true }
);

const navigate = (e) => {
  if (e.keyCode == 40 || e.keyCode == 38) {
    open();

    const items = document.querySelectorAll(".dropdown-item");
    const item = Object.entries(items).find(([, item]) => {
      return [...item.classList].includes("active-dropdown");
    });

    if (!lodash.isEmpty(item)) {
      const elem = item[1];
      const navigateToElement =
        e.keyCode == 40 ? elem.nextElementSibling : elem.previousElementSibling;
      elem.classList.remove("active-dropdown");

      if (navigateToElement == null) {
        const resetIndex = e.keyCode == 40 ? 0 : items.length - 1;
        items[resetIndex].classList.add("active-dropdown");
        active.value = items[resetIndex].innerText;
      } else {
        navigateToElement.classList.add("active-dropdown");
        active.value = navigateToElement.innerText;
      }
    }
  }
};

const { toggleDropdown, isActiveDropdown } = useActiveToggler();
</script>

<style lang="scss" scoped>
.dropdown::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: -12px;
  right: -1px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.active-dropdown {
  background: rgba(229, 231, 235, 1);
}
.relative {
  margin-top: 1.5rem;
}
</style>